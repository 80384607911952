exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog_article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog_category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-work-id-js": () => import("./../../../src/templates/work_id.js" /* webpackChunkName: "component---src-templates-work-id-js" */)
}

